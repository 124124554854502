<template>
  <v-container>
      <v-row class="justify-center">{{this.getLoadingText()}}</v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

// get the user info and redirect to dashboard
export default {
  name: 'signing-in',
  methods: {
    ...mapActions('user', ['logoutUser', 'getAuthInfo', 'setUserRoleData']),
    ...mapActions(['setNotification']),
    getLoadingText() {
      const { new: isNew } = this.$route.query;
      if (isNew === 'true') {
        return 'Please wait...';
      }
      return 'Please wait...';
    },
  },
  data() {
    return ({
      timer: null,
    });
  },
  async mounted() {
    try {
      const {
        firstName,
        lastName,
        email,
        profilePic,
      } = this.$route.query;
      // localStorage.setItem('__bs_valid', JSON.stringify({ token }));
      const roleName = await localStorage.getItem('role');
      const loginType = await localStorage.getItem('loginType');
      const data = await this.setUserRoleData({
        firstName,
        lastName,
        email,
        profilePic,
        roleName,
        loginType,
      });
      if (data.success === true) {
        setTimeout(async () => {
          // await this.getAuthInfo(data.token);
          this.$router.push('/dashboard');
        }, 500);
      }
      if (data.success === false) {
        this.setNotification(
          {
            message: data.data,
            type: 'error',
          },
          { root: true },
        );
        if (loginType === 'register') {
          setTimeout(async () => {
            this.$router.push(`/register?onboarding_role=${roleName}`);
          }, 700);
        } else {
          setTimeout(async () => {
            this.$router.push('/login');
          }, 700);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      this.$router.push('/login');
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
